import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

import { images } from "../../constants";
import { client, urlFor } from "../../client";
import { AppWrap, MotionWrap } from "../../wrapper";
import "./About.scss";
import DocumentMeta from "react-document-meta";
import useAnalyticsEventTracker from '../../useAnalyticsEventTracker';



const About = () => {
  const [abouts, setAbouts] = useState([]);
  const [aboutMe, setAboutMe] = useState({});
  const [aboutMeData, setAboutMeData] = useState({});
  const [aboutMeDataTwo, setAboutMeDataTwo] = useState({});
  const gaEventTracker = useAnalyticsEventTracker('About Me');


  useEffect(() => {
    const aboutsQuery = '*[_type == "abouts"]';
    const aboutMeQuery = `*[_type == "aboutme"][0]{
      profileImg,
      description,
     "resumeUrl": resume.asset -> url
    }`;
    const aboutMeDescriptionQuery = `*[_type == "aboutme"][1]{
      description
    }`;
    const aboutMeDescriptionTwoQuery = `*[_type == "aboutme"][2]{
      description
    }`;
    client.fetch(aboutsQuery).then((data) => {
      setAbouts(data);
    });
    client.fetch(aboutMeQuery).then((data) => {
      setAboutMe(data);
    });
    client.fetch(aboutMeDescriptionQuery).then((data) => {
      setAboutMeData(data);
    });
    client.fetch(aboutMeDescriptionTwoQuery).then((data) => {
      setAboutMeDataTwo(data);
    });
    /*
    client.fetch(
     `*[_type == "aboutme"][0]
      profileImg,
      description[0]{children[0]{"short_description" : text}},
     "resumeUrl": resume.asset -> url
    }`

  )
      .then((data) => setAboutMeData(data[0]));*/

  }, []);

  const viewResumeHandler = () => {
    window.open(aboutMe.resumeUrl, "_blank")
    gaEventTracker('downloaded_cv')
  };

  const meta = {
    title: 'Beimnet Zewdu',
    description: 'This is the portfolio website, of Beimnet Zewdu a mobile and web applications developer in Addis Ababa, Ethiopia. Beimnet Zewdu is an experienced Mobile and Web Developer with over five years of experience. He worked on ride-hailing applications, e-commerce application and so many more. This website shows some of his works and skills.',
    canonical: 'https://beimnetzewdu.com',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'Beimnet, Beimnet Zewdu, Beimnet Zewdu Kebede, Mobile Developer, Web Developer, Website Developer, Website Design, Android Developer, Android App Developer, Mobile Development, Android Development, Android App Development, Best Mobile Developer, Best Mobile App Developer, Best Android App Developer, Best Web Developer,  Best Mobile Developer in Ethiopia, Best Mobile Developer in Addis, Best Mobile Developer in Addis Ababa, Best Mobile Developer in Addis Abeba, Best Web Developer in Ethiopia, Best Web Developer in Addis Ababa, Best Web Developer in Addis Abeba, Best Developer in Ethiopia, Best Developer in Addis Ababa, Beautiful Website, React Developer'
        }
    }
  };



  return (
    <>
   <DocumentMeta {...meta} />
      <h2 className="head-text">
      Turning <span>Coffee</span> into <span>Code</span>
        <br />
        & <span> Ideas </span> into <span>Reality.</span>
      </h2>

      <div className="app__about-context app__flex">
        <div className="app__about-img app__flex">
          <div className="app__flex">
            <img
              src={
                aboutMe.profileImg
                  ? urlFor(aboutMe.profileImg)
                  : images.aboutmine
              }
              alt="Profile"
            />
          </div>
        </div>
        <div className="app__about-data app__flex">
          <h2 className="head-text"><span>About Me</span></h2>
          <p
            className="p-text"
            id="p-text-id"
            dangerouslySetInnerHTML={{ __html: aboutMe.description }}
          ></p>
          <br></br>
          <p
            className="p-text"
            id="p-text-id"
            dangerouslySetInnerHTML={{ __html: aboutMeData.description }}
          ></p>
            <br></br>
          <p
            className="p-text"
            id="p-text-id"
            dangerouslySetInnerHTML={{ __html: aboutMeDataTwo.description }}
          ></p>

        {/*   <BlockContent
            blocks={aboutMeData}
            projectId="xammc2oj" dataset="production"
            />*/}

          <div> 
           {/* <button className="portfolio-button" onClick={viewResumeHandler}>
              View CV
            </button>
            */} 
          </div>
        </div>
      </div>

      <div className="app__profiles">
        {abouts.map((about, index) => (
          <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 1.1 }}
            transition={{ duration: 0.2, type: "tween" }}
            className="app__profile-item"
            key={about.title + index}
          >
            <img src={urlFor(about.imgUrl)} alt={about.title} />
            <h2 className="bold-text" style={{ marginTop: "20px", color: "#1b2598"}}>
              {about.title}
            </h2>
            <h2 className="p-text" style={{ marginTop: "10px" }}>
              {about.description}
            </h2>
          </motion.div>
        ))}
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(About, "app__about"),
  "about",
  "app__whitebg"
);
